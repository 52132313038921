.overview-wrapper {
    height: 100%;
    overflow: auto;
    align-items: stretch;
    display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    grid-template-columns: repeat(auto-fill, 18rem);
    /* grid-template-columns: repeat(auto-fit, 45rem); */
    grid-gap: var(--padding);
}

.repo-card {
    border-color: var(--box-border-color);
    height: 100%;
    overflow: hidden;
}

.repo-card > .card-header {
    background-color: var(--box-secondary-color);
    border-color: var(--box-border-color);
}

.repo-card > .card-body {
    background-color: var(--box-main-color);
    display: grid;
    grid-template-rows: auto min-content;
}

.repo-card > .card-body > .card-body-bar {
    background-color: var(--box-tertiary-color);
}
