
.about-grid {
    display: grid;
    
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: 'about recommended languages'
                         'about recommended links';

    gap: 1.5rem;
    /* text-align: center; */
    height: 100%;
    /* width: 75%; */
    overflow-y: auto;
    align-items: stretch;
}

@media only screen and (max-width: 97rem) {
    
    .about-grid {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, auto);
        grid-template-areas: 'about recommended'
                             'about links';
        /* Padding for the scrollbar: */
        padding-right: 1rem; 
    }
}

@media only screen and (max-width: 72rem) {
    
    .about-grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas: 'about' 
                             'links'
                             'recommended';
        /* Padding for the scrollbar: */
        padding-right: 1rem; 
    }
}

@media only screen and (max-width: 47rem) {
    
    .about-grid {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3, auto);
        grid-template-areas: 'about'
                             'links'
                             'recommended'
                             'languages';
        /* Remove padding for the scrollbar: */
        padding-right: 0rem; 
    }
}

.about-grid > .about {
    grid-area: about;
}

.about-grid > .skills {
    grid-area: skills;
}

.about-grid > .links {
    grid-area: links;
}

.about-grid > .recommended {
    grid-area: recommended;
}