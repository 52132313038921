.input-group>.input-group-prepend {
    flex: 0 0 25%;
}
.input-group .input-group-text {
    width: 100%;
}

.italic {
    font-style: italic
}

.form-control.is-warning {
    border-color:#ffc107;
    padding-right:calc(1.5em + .75rem);
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ffc107'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ffc107' stroke='none'/%3e%3c/svg%3e");
    background-repeat:no-repeat;
    background-position:right calc(.375em + .1875rem) center;
    background-size:calc(.75em + .375rem) calc(.75em + .375rem)
}
.form-control.is-warning:focus {
    border-color:#ffc107;
    box-shadow:0 0 0 .25rem rgba(255, 193, 7,.25)
}
textarea.form-control.is-warning {
    padding-right:calc(1.5em + .75rem);
    background-position:top calc(.375em + .1875rem) right calc(.375em + .1875rem)
}
.form-select.is-warning {
    border-color:#ffc107;
    padding-right:4.125rem;
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23ffc107'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23ffc107' stroke='none'/%3e%3c/svg%3e");
    background-position:right .75rem center,center right 2.25rem;
    background-size:16px 12px,calc(.75em + .375rem) calc(.75em + .375rem)
}
.form-select.is-warning:focus {
    border-color:#ffc107;
    box-shadow:0 0 0 .25rem rgba(255, 193, 7,.25)
}
.form-check-input.is-warning {
    border-color:#ffc107
}
.form-check-input.is-warning:checked {
    background-color:#ffc107
}
.form-check-input.is-warning:focus {
    box-shadow:0 0 0 .25rem rgba(255, 193, 7,.25)
}
.form-check-input.is-warning~.form-check-label {
    color:#ffc107
}