/* .container {
    --padding: 1.5rem;
    padding: var(--padding);
    margin: var(--padding);
    width: calc(100% - var(--padding) * 2);

    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    
    max-width: 100%;
} */
/* 
@media (min-width: 880px) {
    .enter_one {
        display: none;
    }
}

@media (min-width: 450px) {
    .enter_two {
        display: none;
    }
}

@media (max-width: 449px) {
    .container {
        margin: 0;
        width: 100%;
        border: none;
    }
} */

/* For a line with a button in front */
div.background {
    position: relative;
    z-index: 1;
}

div.background:before {
    border-top: 1px solid #212529;
    content:"";
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%; left: 0; right: 0; bottom: 0;
    width: 100%;
    z-index: -1;
}

div.background button { 
    /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
    background: #fff; 
}

.long {
    min-width: 9rem;
}

.try-output {
    /* Ensure that the output is near white, despite the field being readonly */
    /* background-color: #fff !important; */
    background-color: #FBFCFC !important;
}