#root {
    overflow: hidden;
    height: 100%;
}

html,
body {
    --padding: 1.5rem;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;

    background-color: var(--bg-color);
    color: var(--box-normal-text-color);
    /* font-family: var(--bs-font-monospace) */
    font-family: "Open Sans", "Segoe UI";
    /* font-family: "Roboto"; */
}

h5,
h6 {
    font-weight: 300;
}

h6 {
    margin: 0;
}

.fg-1 {
    flex-grow: 1;
}

.fg-inf {
    flex-grow: 9999;
}

code {
    font-family: "Roboto Mono";
}

a:link {
    color: var(--link-color);
}

a:visited {
    color: var(--visited-link-color);
}

a.no-bottom-line {
    text-decoration: none;
}

a.bold,
NavLink.bold {
    font-weight: bold;
}

a.dark {
    text-decoration: none;
    color: var(--box-normal-text-color);
}

.popover-body {
    padding: 0;
}

.spinner-border {
    transition: opacity 0.2s linear 0.1s;
}

.box {
    padding: var(--padding);
    background-color: var(--box-main-color);
    border: 1px solid var(--box-border-color);
    border-radius: 0.5rem;
    /* box-shadow: 3px 3px var(--box-border-color); */
}

.layout {
    max-width: 100%;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: min-content auto;
    grid-template-areas: 'header header''sidebar content';
    gap: var(--padding);
    padding: var(--padding);

    overflow: hidden;
}

.card {
    border-radius: 0.5rem;
}

.header {
    grid-area: header;
}

.sidebar {
    grid-area: sidebar;
    width: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
}

.content {
    grid-area: content;
    overflow: hidden;
    height: 100%;
}

.header-flex {
    display: flex;
    gap: 1.5rem;
    align-items: center;
}

@media only screen and (max-width: 47rem) {
    .layout {
        grid-template-columns: auto;
        grid-template-rows: min-content min-content auto;
        grid-template-areas: 'header''sidebar''content';
    }

    .sidebar {
        width: 100%;
        overflow-y: hidden;
    }

    #root {
        height: auto;
    }

    .header-flex {
        display: grid;
    }
}

hr {
    color: var(--box-border-color);
    opacity: 1;
}

.tac {
    text-align: center;
}

.external-link-icon {
    opacity: 25%;
    margin-left: 1px;
    margin-top: 4px;
    /* This makes it a bit smaller */
    padding-left: 3px;
}

.list-group-item {
    /* In many cases, we want to not set the color of a list-group-item to #FFF */
    background-color: inherit;

    border: 1px solid var(--box-border-color);
}

.text-muted {
    color: var(--box-light-text-color) !important;
}