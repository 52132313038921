.dvd_container {
    width: 100%;
    height: 100%;
    position: relative;
}

.dvd_container .box {
    width: 230px;
    height: 135px;
    position: absolute;

    -webkit-animation: moveX 10s linear 0s infinite alternate, moveY 5.2s linear 0s infinite alternate;
    -moz-animation: moveX 10s linear 0s infinite alternate, moveY 5.2s linear 0s infinite alternate;
    -o-animation: moveX 10s linear 0s infinite alternate, moveY 5.2s linear 0s infinite alternate;
    animation: moveX 10s linear 0s infinite alternate, moveY 5.2s linear 0s infinite alternate;
}

.dvd_container .delay {
    -webkit-animation-delay: -12.5s;
    -moz-animation-delay: -12.5s;
    -o-animation-delay: -12.5s;
    animation-delay: -12.5s;
}

.dvd_container .large_delay {
    -webkit-animation-delay: -50s;
    -moz-animation-delay: -50s;
    -o-animation-delay: -50s;
    animation-delay: -50s;
}

@-webkit-keyframes moveX {
    from { left: 0; } to { left: calc(100% - 230px); }
}
@-moz-keyframes moveX {
    from { left: 0; } to { left: calc(100% - 230px); }
}
@-o-keyframes moveX {
    from { left: 0; } to { left: calc(100% - 230px); }
}
@keyframes moveX {
    from { left: 0; } to { left: calc(100% - 230px); }
}
@-webkit-keyframes moveY {
    from { top: 0; } to { top: calc(100% - 135px); }
}
@-moz-keyframes moveY {
    from { top: 0; } to { top: calc(100% - 135px); }
}
@-o-keyframes moveY {
    from { top: 0; } to { top: calc(100% - 135px); }
}
@keyframes moveY {
    from { top: 0; } to { top: calc(100% - 135px); }
}