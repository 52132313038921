
.readme-card {
    border: 1px solid var(--box-border-color);
    overflow: hidden;
}

.readme-card > .card-header {
    background-color: var(--box-secondary-color);
    border-color: var(--box-border-color);
}

.readme-card > .card-body {
    background-color: var(--box-main-color);
}

.readme-card > .card-body > .card-body-bar {
    background-color: var(--box-tertiary-color);
    padding: 0;
    margin: 0;
    border: 1px solid var(--box-border-color);
    overflow: hidden;
}

.readme-card > .card-body > .card-body-bar > .list-group-item{
    border: 1px solid var(--box-border-color);
    margin: -1px;
    border-radius: 0px;
}

.row-grid {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 100%;

    display: grid;
    /* grid-template-columns: repeat(2, 1fr); */
    /* grid-template-columns: minmax(min-content, max-content); */
    /* grid-auto-flow: dense; */
    gap: 1.5rem;

    padding-right: 1.5rem;
}

@media only screen and (max-width: 47rem) {
    .row-grid {
        padding-right: 0;
    }
}

.row-grid > .box {
    min-width: 0;
}

.row-grid > .box > pre,
.row-grid > .box > code {
    background-color: var(--box-secondary-color);
}

.row-grid > .box > table, 
.row-grid > .box > table > thead > tr > th,
.row-grid > .box > table > tbody > tr > td,
.row-grid > .box > table > tr > th,
.row-grid > .box > table > tr > td,
.row-grid > .box > table {
    border: 1px solid var(--box-border-color);
    padding: 0.5rem;
}

.row-grid > .box > :last-child {
    margin-bottom: 0;
}