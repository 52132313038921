
.projects-header {
    font-weight: 600;
    /* TRBL */
    padding: 0rem 0rem 1rem 1.25rem;
    display: flex;
    border-bottom: 1px solid var(--box-border-color);
}

/* .projects-header:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb%2833,37,41%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='1' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transform: rotate(270deg);
    background-repeat: no-repeat;
    background-size: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
} */

.btn-toggle {
    display: inline-flex;
    align-items: center;
    margin-left: -1.25em;
    padding: 0;
    font-weight: 600;
    border: 0;
    color: var(--box-normal-text-color);
}

.btn-toggle:hover,
.btn-toggle:focus {
    box-shadow: none;
}

.btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgb%2833,37,41%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.25' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
}

.btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
}

.btn-toggle-nav a {
    display: inline-flex;
    /* padding: .1875rem 1.25rem; */
    padding-top: 0.1875rem;
    /* margin-top: .125rem; */
    /* margin-left: 1.25rem; */
    text-decoration: none;
}

.sidebar > .list-group > .list-group-item {
    padding-left: 1.25rem;
}